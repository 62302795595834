<template>
  <b-row class="mt-2 justify-content-center">
    <b-col>
      <div
        v-if="$can('store','global-SubclassificationController')"
        class="text-right"
      >
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add SubClassification</span>
        </b-button>
      </div>

      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            md="4"
            class="mt-1 mt-md-0"
          >
            <status-filter
              :status.sync="status"
            />
          </b-col>
          <b-col
            cols="12"
            class="mb-1"
          >
            <classification-select :class.sync="classId" />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>

        </b-row>
      </b-card>

      <sub-class-table
        ref="parentTable"
        v-bind="{
          getSubClass,
          pagination
        }"
        :sub-class.sync="subClass"
        @onSort="onSortChanged"
      />

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>

    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Classification -->
      <b-modal
        id="add-modal"
        centered
        title="Add SubClassification"
        hide-footer
        no-close-on-backdrop
      >
        <add-sub-classifications
          @refresh="refreshTable"
        />
      </b-modal>

      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit SubClassification"
        hide-footer
        no-close-on-backdrop
        @hidden="resetSubclass"
      >
        <edit-sub-classifications
          v-bind="{subClass}"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>

import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import deleteRecord from '@/common/compositions/common/deleteRecord'
import AddSubClassifications from '@/common/components/GeneralSettings/sub-classifications/AddSubClassifications.vue'
import EditSubClassifications from '@/common/components/GeneralSettings/sub-classifications/EditSubClassifications.vue'
import SubClassTable from '@/common/components/GeneralSettings/sub-classifications/SubClassTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import ClassificationSelect from '@/common/components/common/FormInputs/ClassificationSelect.vue'
import StatusFilter from '@/common/components/common/FormInputs/StatusFilter.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  components: {
    AddSubClassifications,
    EditSubClassifications,
    SubClassTable,
    Pagination,
    Search,
    SearchButton,
    ClassificationSelect,
    StatusFilter,
  },
  data() {
    return {
      subClass: {},
      searchQuery: null,
      classId: null,
      status: null,
      sortingQuery: null,
    }
  },
  setup() {
    const { confirmOperation, table } = deleteRecord()
    const { pagination } = paginationData()
    return {
      confirmOperation,
      table,
      pagination,
    }
  },
  methods: {
    resetSubclass() {
      this.subClass = {}
    },
    onSortChanged(ctx) {
      const lookup = {
        name: 'orderByName',
        status: 'orderByStatus',
        'classification.name': 'orderByClass',
      }

      if (ctx.sortBy) { this.sortingQuery = `${lookup[ctx.sortBy]}=${ctx.sortDesc ? 'desc' : 'asc'}` }
    },
    getSubClass() {
      return this.$entities
        .get(`internalops/sub-classifications?${this.sortingQuery}`, {
          params: {
            name: this.searchQuery,
            classification: this.classId,
            page: this.pagination.currentPage,
            status: this.status,
            paginated: true,
          },
        }).then(res => {
          const items = res.data.data

          this.pagination.totalRows = res.data.total
          return items || []
        }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
