<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Name"
          label-for="classification"
        >
          <b-form-input
            id="classification"
            v-model="subClass.name"
            :state="getValidationState(validationContext)"
            autofocus
            placeholder="Sub Classification Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <classification-select :class.sync="subClass.class_id" />

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <submit-button
          :handle-submit="handleSubmit"
          :submit="addSubClassification"
        />
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import ClassificationSelect from '../../common/FormInputs/ClassificationSelect.vue'

export default {
  name: 'AddSubClassification',
  components: {
    SubmitButton,
    ClassificationSelect,
  },
  props: {
    classifications: { type: Array, default: () => [] },
  },
  data() {
    return {
      subClass: {},
    }
  },
  setup() {
    // Form Validation
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert } = handleAlerts()

    return {
      getValidationState,
      successfulOperationAlert,

    }
  },
  methods: {
    addSubClassification() {
      return this.$entities.post('internalops/sub-classifications', this.subClass).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Subclassification is added successfully')
        this.$emit('refresh')
      })
    },
  },
}
</script>

<style>

</style>
